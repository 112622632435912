<template>
<div class="indexScreen" id="screenTotal">
    <div id="myMap">
      <mapAggro></mapAggro>
    </div>

    <HeaderTop title="溯源可视化大屏"></HeaderTop>
    
    <div id="main">
      <div id="mainBox">
        <div id="lefts" style="left:3%;">
          <div class="container-item-box1">
                  <boxTitle text="产品展示"></boxTitle>
                  <el-carousel :interval="3000" :autoplay="true" >
                      <el-carousel-item
                      v-for="(item, index) in imgList"
                      :key="index"
                      >
                      <img class="swiperImg" :src="item" alt=""/>
                      </el-carousel-item>
                  </el-carousel>
              </div>
              <div class="container-item-box2">
                  <boxTitle text="基础信息"></boxTitle>
                  <div class="basic-msg">
                      <div class="basic-msg-item" v-for="(item,i) in basicList" :key="i">
                          <img :src="require(`@/assets/aggroTrace/basic-${i+1}.png`)" alt="">
                          <div class="right">
                              <div class="name">{{ item.name }}</div>
                              <div class="number">{{ item.number || 0 }}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="container-item-box3">
                  <boxTitle text="气象信息"></boxTitle>
                  <div class="weather">
                      <div class="weather-top">
                          <i :class="`icon qi-${weather.iconDay }`"></i>
                          <div class="middle">
                              <div class="text"><span>{{ weather.textDay }}</span> <span>{{ weather.windDirDay }}</span></div>
                              <div class="number">{{weather.tempMin}}℃~{{weather.tempMax}}℃</div>
                          </div>
                          <div class="right"><img src="@/assets/aggroTrace/location.png" alt=""> {{ city }}</div>
                      </div>
                      <div class="weather-bottom">
                          <div class="weather-bottom-item">
                              <div class="left">{{ weather.windDirDay }}</div>
                              <div class="right">{{ weather.windScaleDay  }}级</div>
                          </div>
                          <div class="weather-bottom-item">
                              <div class="left">大气压强</div>
                              <div class="right">{{ weather.pressure  }}hPa</div>
                          </div>
                          <div class="weather-bottom-item">
                              <div class="left">能见度</div>
                              <div class="right">{{ weather.vis  }}公里</div>
                          </div>
                          <div class="weather-bottom-item">
                              <div class="left">湿度</div>
                              <div class="right">{{ weather.humidity }}%</div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
        <div class="center">
           <div class="container-center-box3">
                <boxTitle text="数据统计"></boxTitle>
                <div class="container-center-box3-chart">
                    <barChart></barChart>
                    <lineChart></lineChart>
                </div>
            </div>
        </div>
        <div id="rights" style="right:3%;">
          <div class="container-item-box1">
              <boxTitle text="预警信息"></boxTitle>
              <!-- <div class="container-item-box1-icon">
                  <div v-for="(item,key) in warnIconList" :key="key"><img :src="require(`@/assets/aggroTrace/${item.icon}.png`)" alt=""> {{ item.text }} {{ item.numeber }}</div>
              </div> -->
              <dv-scroll-board  :config="warnConfig" />
          </div>
          <div class="container-item-box2" style="height: 32%;">
              <boxTitle text="批次信息"></boxTitle>
              <dv-scroll-board  :config="growConfig" />
          </div>
          <div class="container-item-box3" style="height: 38%;">
              <boxTitle text="任务统计"></boxTitle>
              <pieChart></pieChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderTop from "@/components/breadCrumb/HeaderTop.vue";
import boxTitle from "@/components/breadCrumb/boxTitle.vue";
import barChart from "@/components/charts/barChart.vue"
import lineChart from "@/components/charts/lineChart.vue"
import pieChart from "@/components/charts/pieChart.vue"
import mapAggro from "./mapAggro.vue"

export default{
  components:{HeaderTop,boxTitle,barChart,lineChart,mapAggro,pieChart,},
  data(){
        return{
            imgList: [],
            basicList:[],
            warnIconList:[{icon: 'insect',text:'病虫虫害',numeber:0},{icon: 'weather',text:'气象灾害',numeber:0},{icon: 'water',text:'旱情涝情',numeber:0},{icon: 'cold',text:'冷害冻害',numeber:0}],
            warnConfig:{
                header: ['报警时间 ', '报警设备', '报警值'],
                data:[]
            },
            growConfig:{
                header: ['所属基地 ', '名称', '面积', '作业开始时间'],
                data:[]
            },
            weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
            weather:{},
          city:"",
          county:"",

        }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
        this.getLifecycle()
        this.getPlant()
        this.getBasic()
        this.getWarnLog()
      this.getPowerName();
    },
    getPowerName(){
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {

        if(res.data.state == 'success'){
          this.city = res.data.datas[0].city;
          this.county = res.data.datas[0].county;

          this.requestLookup()
        }
      })
    },
    // 溯源产品
    getLifecycle(){
        this.$get('/lifeCycles/getLifeCycleList',{
            page: 1,
            size: 100
        }).then((res) => {
            if (res.data.state == "success") {
                this.imgList = res.data.datas.map(v=>v.cropImg)
              //console.log("aaaaaaaaa++++++++++++")
              //console.log(this.imgList)
            }
        });
    },
    // 基础信息
    getBasic(){
        this.$get('/ruralOverview/getRuralOverviews',{
            page: 1,
            size: 100
        }).then(res=>{
            if (res.data.state == 'success') {
                const data  = res.data.datas[0]
                this.basicList = [{
                    name:'管理人员',
                    number: data.managePeoples + "人"
                    },{
                    name:'主要产品',
                    number: data.mainCrops
                    },{
                    name:'面积',
                    number: data.plantingArea + "亩"
                    },{
                    name:'设备数量',
                    number: data.agriMachineryNum + "台"
                    },{
                    name:'企业数量',
                    number: data.firms
                    },{
                    name:'产品销量',
                    number: data.sales + "吨"
                    }]
            }
        })
    },
    // 信息
    getPlant(){
        this.$get('/plantingBatch/getPlantingBatchList',{
            page: 1,
            size: 100
        }).then((res) => {
            if (res.status == 200) {
                const data = res.data.datas
                this.growConfig={
                    header: ['所属基地 ', '名称', '面积', '开始时间'],
                    data: data.reverse().map(v=>[v.baseName,v.cropName,v.area + '亩',v.createTime.split(' ')[0]]),
                }
            }
        });
    },
     // 天气
    requestLookup() {
      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: { adm: this.city, location: this.county, key: this.weatherKey },
      }).then((res) => {
        if (res.data.code == 200) {
          let { location } = res.data;
          //console.log("+++++++++",location)
          this.getWeather(location);
        }
      });
    },
      // 实时天气
    getWeather(location) {
      this.$axios({
        method: "GET",
        url: "https://devapi.qweather.com/v7/weather/3d",
        params: { location: location[0].id, key: this.weatherKey },
      }).then((res) => {
        if (res.data.code == 200) {
          this.weather = res.data.daily[0]
        }
      });
    },
    // 报警信息
    getWarnLog() {
      this.$get(`/warnManage/pageWarnLogs`, { page: 1 }).then((res) => {
        if (res.data.state == "success") {
          this.warnConfig={
                header: ['报警时间', '报警设备', '报警值'],
                data: res.data.datas.map(v=>[v.createTime,v.deviceName,v.warnData]),
                align: ['center','center','center']
            }
        }
      });
    },
  }
}
</script>
<style src="@/style/font/font.css" scoped></style>
<style scoped lang="less">
  @import '~@/style/font/screen.less';
</style>
<style  lang="scss" scoped>
#lefts,#rights{
  width: 25%;
  height: calc(100vh - 80px);
  position: absolute;
  bottom: 0;
  .container-item-box1{
    .swiperImg{
      max-width: 100%;
      max-height: 100%;
      text-align: center;
    }
  }
  .container-item{
    position: absolute;
    width: 28%;
    height: calc(100vh - 80px);
    top: 90px;
    &-box1{
        height: 30%;

        &-icon{
            margin: 5px auto;
            width: 90%;
            padding: 5px 0;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #0e343e;
            border: 1px solid #0c5854;
            &>div{
                margin-right: 10px;
                img{

                    vertical-align: middle;
                }
            }
            &>div:last-child{
                margin-right: 0;
            }
        }
    }
    &-box3{
        height: 36%;
        .weather{
            height: calc(100% - 90px);
            margin: 15px 4%;
            border-radius: 10px;
        //    background-image: url('~@/assets/aggroTrace/box_bg.png');
        //    background-size: 100% 100%;
        //    background-repeat: no-repeat;
            background-image: linear-gradient(to right, rgba(35,98,115,0.24), rgba(35,98,115,0));
            border: 1px solid #146769;
            &-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 8%;
                border-bottom: 1px solid #146769;
                img{
                width: 35px;
                height: 35px;
                }
                .middle{
                font-size: 18px;
                .text{
                    color: #fff;
                    margin-bottom: 6px;
                }
                .number{
                    color: #0BC0B8;
                }
                }
                .right{
                font-size: 22px;
                color: #fff;
                img{
                    width: 26px;
                    height: 28px;
                    vertical-align: middle;
                }
                }
            }
            &-bottom{
                padding: 20px 3% 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                &-item{
                    display: flex;
                    align-items: center;
                    width: 48.5%;
                    margin-bottom:20px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    border-radius: 2px;
                    .left{
                        width: 50%;
                        background: rgba(12,88,84,0.35);
                        // background: url('~@/assets/index/poline.png')  100% 100%;

                        font-size: 14px;
                        color: #b1e1ff;
                    }
                    .right{
                        width: 50%;
                        background: #146769;
                        font-size: 20px;
                        color: #fff;
                    }
                }
            }
        }
    }
    &-box2{
        height: 34%;
        .basic-msg{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 10px;
            &-item{
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img{
                    width: 68px;
                    height: 71px;
                }
                .right{
                    flex: 1;
                        margin-left: 10px;
                    .name{
                        // background: rgba(12,88,84,0.35);
                        background: url('~@/assets/index/poline.png') repeat-x 100% 100%;
                        font-size: 14px;
                        color: #C3FFFC;
                        padding: 4px 8px;
                        margin-bottom: 3px;
                    }
                    .number{
                        font-size: 22px;
                        color: #fff;
                        padding-left: 8px;
                    }
                }
            }
        }
    }
   }
}
.center {
    position: absolute;
    width: 40%;
    display: flex;
    align-items: flex-end;
    height: 34%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    .container-center-box3{
        margin-top: 10px;
        height: 100%;
        width: 100%;
        &-chart{
            background-color: rgba(20,103,105,0.15);
            height: calc(100% - 75px);
            padding: 8px 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

/deep/ .el-carousel__container{
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    border: 1px solid #23D5D5;
    box-sizing: border-box;
}
/deep/ .el-carousel--horizontal{
    padding: 10px 30px;
    height: calc(100% - 42px);
    .el-carousel__indicators--horizontal{
        bottom: 15px;
        .el-carousel__button{
            background-color: #21818F;
            width: 15px;
            border-radius: 7px;
            height: 7px;
            opacity: 1;
        }
    }
    // .el-carousel__indicators--horizontal.is-active .el-carousel__button{
    //     background-color: #23D5D5 !important;
    // }
}
/deep/.el-carousel__indicator.is-active button {
      width: 30px !important;
      border-radius: 4px;
      height: 7px;
      background-color: #23D5D5 !important
    }
/deep/ .dv-scroll-board{
    width:100%;
    height: calc(100% - 70px);
}
/deep/ .dv-scroll-board .header {
    width: 90%;
    margin: 0 auto;
    background: url('~@/assets/index/alarm.png') repeat-x;
    background-color: transparent !important;
    background-size: cover;
    height: 35px;
    color:#07d0c7;
    margin-bottom: 5px;
    font-size: 14px;
}
/deep/ .dv-scroll-board .rows{
    width: 90%;
    margin: 0 auto;
    background-color: rgba(12,88,54,0.3) !important;
    border-radius: 5px;
}
/deep/ .dv-scroll-board .rows .row-item{
    height: 33px;
    line-height: 33px;
    font-size: 14px;
    background-color: transparent !important;
    
}
// .container-item-box2 /deep/ .dv-scroll-board{
//     width:100%;
//     height: calc(100% - 70px) !important;
// }


.icon{
    font-size: 40px;
}
</style>

